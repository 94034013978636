<template>
    <div class="cosmetics-list">
        <!-- banner -->
        <mall-banner
            router-name="前往摄影器材专区"
            router-path="/equipment">
        </mall-banner>
        <div class="container">
            <!-- filter -->
            <list-filter
                :sort-list="testSort">
                <template #filter>
                    <filter-list
                        :filter-array="testArray1">
                    </filter-list>
                </template>
            </list-filter>
            <!-- list -->
            <ul class="cosmetics-list-item clearfix">
                <li v-for="(item, index) in 12"
                    :key="index">
                    <goods-item
                        router-path="/cosmetics/detail">
                    </goods-item>
                </li>
            </ul>
            <!-- page -->
            <div class="cosmetics-list-page">
                <el-pagination
                    layout="prev, pager, next"
                    :page-count="100"
                    :pager-count="11">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                testArray1: [
                    {
                        value: 1,
                        label: "彩妆套装"
                    },
                    {
                        value: 2,
                        label: "口红粉底液/膏"
                    },
                    {
                        value: 3,
                        label: "气垫BB/BB霜"
                    },
                    {
                        value: 4,
                        label: "气垫CC/CC霜"
                    },
                    {
                        value: 5,
                        label: "蜜粉/散粉"
                    },
                    {
                        value: 6,
                        label: "遮瑕"
                    },
                    {
                        value: 7,
                        label: "粉饼"
                    },
                    {
                        value: 8,
                        label: "隔离霜/妆前乳"
                    },
                    {
                        value: 9,
                        label: "唇彩唇蜜/唇釉"
                    },
                    {
                        value: 10,
                        label: "男士彩妆"
                    },
                    {
                        value: 11,
                        label: "其它彩妆"
                    },
                    {
                        value: 12,
                        label: "香水"
                    },
                    {
                        value: 13,
                        label: "眉笔/眉粉"
                    },
                    {
                        value: 14,
                        label: "美甲产品"
                    },
                    {
                        value: 15,
                        label: "眼影"
                    },
                    {
                        value: 16,
                        label: "眼线笔/眼线液"
                    },
                    {
                        value: 17,
                        label: "睫毛膏/增长液"
                    },
                    {
                        value: 18,
                        label: "腮红/胭脂"
                    },
                    {
                        value: 19,
                        label: "唇笔/唇彩笔"
                    }
                ],
                testSort: [
                    {
                        value: 1,
                        label: "热门推荐"
                    },
                    {
                        value: 2,
                        label: "销量最多"
                    },
                    {
                        value: 3,
                        label: "最新产品"
                    }
                ]
            }
        },
        components: {
            mallBanner: () => import("@/components/imageMall/mallBanner"),
            listFilter: () => import("@/components/imageMall/listFilter"),
            filterList: () => import("@/components/cosmetics/filterList"),
            goodsItem: () => import("@/components/imageMall/goodsItem")
        }
    }
</script>

<style scoped>
.cosmetics-list{
    --color: #fca29a;
}
/* list */
.cosmetics-list-item > li{
    float: left;
    width: 25%;
    padding: 10px;
    margin-top: 50px;
}

/* page */
.cosmetics-list-page{
    margin: 50px 0;
    text-align: center;
}
</style>